const alchemyKey = process.env.REACT_APP_ALCHEMY_KEY;
const { createAlchemyWeb3 } = require("@alch/alchemy-web3");
const web3 = createAlchemyWeb3(alchemyKey);
const contractABI = require('../contract-abi.json')
const contractAddress = "0x66f357c597e124FcA2b4808A4623057e5A92F0a0";
let myContract = new web3.eth.Contract(contractABI, contractAddress);

export const getTotalSupply = () => {
    return myContract.methods.totalSupply().call();
}

export const getMaxSupply = () => {
    return myContract.methods.maxSupply().call();
}

export const getMaxMintSize = () => {
    return myContract.methods.maxMintSize().call();
}

export const getTotalMintCost = (mintNum) => {
    return myContract.methods.computeTotalMintCost(mintNum).call();
}

export const getSaleIsActive = () => {
    return myContract.methods.saleIsActive().call();
}

export const getContract = () => {
    return contractAddress;
}

export const mintNFT = async (mintNum, totalCost) => {

    if (window.ethereum) {

        window.contract = await new web3.eth.Contract(contractABI, contractAddress);

        //set up your Ethereum transaction
        const transactionParameters = {
            to: contractAddress, // Required except during contract publications.
            from: window.ethereum.selectedAddress, // must match user's active address.
            value: '0x' + totalCost.toString(16),
            'data': window.contract.methods.mint(mintNum).encodeABI(), //make call to NFT smart contract 

        };

        //sign the transaction via Metamask
        try {
            const txHash = await window.ethereum
                .request({
                    method: 'eth_sendTransaction',
                    params: [transactionParameters],
                });
            return {
                success: true,
                status: "✅ Thank you! Please check your transaction on Etherscan."
            }
        } catch (error) {
            return {
                success: false,
                status: "😥 Something went wrong: " + error.message
            }

        }

    } else {
        return {
            address: "",
            status: (
                <span>
                    <p>
                        {" "}
                        🦊{" "}
                        <a target="_blank" href={`https://metamask.io/download.html`}>
                            You must install Metamask, a virtual Ethereum wallet, in your
                            browser.
                        </a>
                    </p>
                </span>
            ),
        };
    }

};

export const connectWallet = async (contractChain) => {

    let chainId;

    if (window.ethereum) {

        try {
            chainId = await window.ethereum.request({
                method: "eth_chainId",
            });

            if (chainId != contractChain) {

                alert("Please make sure you select the Ethereum Main Network!");
                return {
                    address: "",
                    status: "🦊 Connect your Metamask wallet using the top right button.",
                };
            }

        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }

        try {
            const addressArray = await window.ethereum.request({
                method: "eth_requestAccounts",
            });
            const obj = {
                status: "👆🏽 Please input the number of NFTs you wish to mint and click Mint NFT!",
                address: addressArray[0],
                myChainId: chainId,
            };
            return obj;
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
                    <p>
                        {" "}
                        🦊{" "}
                        <a target="_blank" href={`https://metamask.io/download.html`}>
                            You must install Metamask, a virtual Ethereum wallet, in your
                            browser.
                        </a>
                    </p>
                </span>
            ),
        };
    }
};

export const getCurrentWalletConnected = async () => {
    let chainId;
    if (window.ethereum) {

        try {
            chainId = await window.ethereum.request({
                method: "eth_chainId",
            });


        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,
            };
        }

        try {
            const addressArray = await window.ethereum.request({
                method: "eth_accounts",
            });
            if (addressArray.length > 0) {
                return {
                    address: addressArray[0],
                    status: "👆🏽 Please input the number of NFTs you wish to mint and click Mint NFT!",
                    myChainId: chainId,
                };
            } else {
                return {
                    address: "",
                    status: "🦊 Connect your Metamask wallet using the top right button.",

                };
            }
        } catch (err) {
            return {
                address: "",
                status: "😥 " + err.message,

            };
        }
    } else {
        return {
            address: "",
            status: (
                <span>
                    <p>
                        {" "}
                        🦊{" "}
                        <a target="_blank" href={`https://metamask.io/download.html`}>
                            You must install Metamask, a virtual Ethereum wallet, in your
                            browser.
                        </a>
                    </p>
                </span>
            ),
        };
    }
};