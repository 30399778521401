import { useEffect, useState } from "react";
import { connectWallet, getCurrentWalletConnected, mintNFT, getTotalSupply, getContract, getTotalMintCost, getMaxMintSize, getMaxSupply, getSaleIsActive } from "./utils/interact.js";
import Countdown from 'react-countdown';


const Minter = () => {

  //State variables

  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [mintNum, setQty] = useState(1);

  const [totalSupply, setTotalSupply] = useState(0);
  const [maxSupply, setMaxSupply] = useState(9999);
  const [maxMintSize, setMaxMintSize] = useState(0);

  const [totalCost, setTotalCost] = useState(0);
  const [avgPrice, setAvgPrice] = useState(0);

  const [connectedChain, setConnectedChain] = useState(0);

  const [soldOut, setSoldOut] = useState("");
  const [soldOutBool, setSoldOutBool] = useState(false);

  const [mintFrozen, setMintFrozen] = useState(true);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [saleActive, setSaleActive] = useState(true);

  const minMints = 1;
  const maxMints = maxMintSize;

  const mintDate = Date.UTC(2022, 4, 16, 4, 0, 0, 0)

  const contractChain = 1;   // 1:Eth Mainnet, 3: Ropsten, 4: Rinkeby, 5: Goerli, 42: Kovan
  const [count, setCount] = useState(0);
  const freq = 1000; // Every 2 seconds

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////OK

  useEffect(() => {

    fetchTotalSupply();
    fetchMaxMintSize();
    fetchSaleActive();
    soldOutCheck();

    setTimeout(() => {
      setCount((count) => count + 1);
    }, freq);

  }, [count]);


  // only get once on first load, because it's fixed in contract
  useEffect(() => {

    fetchMaxSupply();

  }, []);



  /////////////////////////////////////////////////////////////////////////////////////////////////////////////////OK

  const fetchTotalSupply = () => {
    getTotalSupply().then(totalSupply => {
      setTotalSupply(parseInt(totalSupply));

    }).catch(err => {
      console.log(err);
    })
  };

  const fetchMaxMintSize = () => {
    getMaxMintSize().then(maxMintSize => {
      setMaxMintSize(parseInt(maxMintSize));

    }).catch(err => {
      console.log(err);
    })
  };

  const fetchMaxSupply = () => {
    getMaxSupply().then(maxSupply => {
      setMaxSupply(parseInt(maxSupply));

    }).catch(err => {
      console.log(err);
    })
  };

  const fetchSaleActive = () => {
    getSaleIsActive().then(saleActive => {
      setSaleActive(saleActive);

    }).catch(err => {
      console.log(err);
    })
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////OK
  // soldoutcheck is called periodically

  const soldOutCheck = () => {


    if (totalSupply == maxSupply) {

      setSoldOut("SOLD OUT!");
      setSoldOutBool(true);

    }
  };

  useEffect(() => {

    setButtonDisable(soldOutBool || mintFrozen || !saleActive);


  }, [soldOutBool, mintFrozen, saleActive]);

  /////////////////////////////////////////////////////////////////////////////////////////////////OK

  const fetchTotalCost = () => {
    getTotalMintCost(mintNum).then(totalCost => {
      setTotalCost(parseInt(totalCost));

    }).catch(err => {
      console.log(err);
    })
  };

  useEffect(() => {
    fetchTotalCost();
  }, [mintNum, totalSupply]);

  useEffect(() => {
    setAvgPrice(totalCost / mintNum / 1000000000000000000);
  }, [totalCost]);

  ////////////////////////////////////////////////////////////////////////////////////////////////OK
  /// automatic mintNum adjustment to deal with other simultaneous actions

  const periodicMintQtyCheck = () => {
    if (mintNum + totalSupply > maxSupply) {
      setQty(Math.max(1, maxSupply - totalSupply));
    }

  };

  useEffect(() => {
    periodicMintQtyCheck();
  }, [totalSupply]);



  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("👆🏽 Please input the number of NFTs you wish to mint and click Mint NFT!");
        } else {
          setWallet("");
          setStatus("🦊 Connect to Metamask using the top right button.");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  function addChainListener() {
    if (window.ethereum) {
      window.ethereum.on("chainChanged", (chainId) => {
        if (chainId != contractChain) {

          alert("Please make sure you are connected to the Ethereum Main Network!");
          setStatus("👆🏽 You are NOT CONNECTED to the Ethereum Main Network. Please check and connect to the right chain");
        } else {
          setStatus("👆🏽 Please input the number of NFTs you wish to mint and click Mint NFT!");
        }

        setConnectedChain(chainId);
        //window.location.reload();

      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }


  useEffect(() => {

    (async () => {

      const walletResponse = await getCurrentWalletConnected();
      setWallet(walletResponse.address);
      setStatus(walletResponse.status);
      setConnectedChain(walletResponse.myChainId);
    })();

    addWalletListener();
    addChainListener();

  }, []);



  const connectWalletPressed = async () => {

    if (walletAddress == "") {
      const walletResponse = await connectWallet(contractChain);
      setStatus(walletResponse.status);
      setWallet(walletResponse.address);
      setConnectedChain(walletResponse.myChainId);
    }
  };


  const onMintPressed = async () => {

    if (totalSupply == maxSupply) {

      alert("Collection sold out - Minting session is over.")

    } else {

      if (walletAddress == "") {
        alert("Please connect your metamask wallet!")
      } else {
        if (connectedChain != contractChain) {

          alert("Please make sure you select the Ethereum Main Network!");

        } else {
          const { status } = await mintNFT(mintNum, totalCost);
          setStatus(status);
          setQty(1)
        }
      }
    }

  };


  ////////////////////////////////////////////////////////////////////////////////////////////////////////////

  const increNum = () => {
    const maxVal = Math.min(maxMints, maxSupply - totalSupply);
    setQty(Math.max(Math.min(mintNum + 1, maxVal), 1));



  }

  const decreNum = () => {
    setQty(Math.max(mintNum - 1, minMints));
  };


  const reloadPage = () => {
    window.location.reload();
  };

  ////////////////////////////////////////////////////////////////////////////////////////////////////////////



  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      // Render a complete state

      setMintFrozen(false);
      return <p id="mintStart"> Minting session: {((saleActive && (!soldOutBool)) ? 'OPEN' : 'SUSPENDED')}</p>


    } else {
      // Render a countdown

      return (

        <p id="countDown">
          Minting session starts in <br></br> {days} DAYS {hours} HRS {minutes} MINS {seconds} SECS
        </p>
      );
    }
  };


  return (
    <div className="Minter">

      <button id="walletButton" onClick={connectWalletPressed} disabled={buttonDisable}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect Wallet</span>
        )}
      </button>

      <br></br>

      <p id="title">Upper Social Club - L'UOMO</p>

      <Countdown date={mintDate} renderer={renderer} />
      <p id="contract">Contract {getContract()}</p>
      <p id="mintNumber"><b>Latest total minted</b>: {totalSupply} &nbsp;&nbsp;&nbsp;{soldOut}</p>


      <p id="pricePerMint">
        Avg. price per mint ~ {avgPrice.toFixed(4)} ETH
      </p>

      <p id="maxMint">max. {maxMints} mints / tx</p>


      <p id="orderDetail"><b>Mint quantity</b>: {mintNum} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Total cost</b>: {(totalCost / 1000000000000000000).toFixed(3)} ETH </p>
      <button id="decreNumButton" onClick={decreNum} disabled={buttonDisable}>-</button>
      <button id="increNumButton" onClick={increNum} disabled={buttonDisable}>+</button>
      <button id="refreshButton" onClick={reloadPage}>Reload</button>
      <br></br>

      <button id="mintButton" onClick={onMintPressed} disabled={buttonDisable}>
        Mint NFT
      </button>
      <p id="status">
        <b>{status}</b>
      </p>


    </div >
  );
};

export default Minter;
